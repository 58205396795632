import React from 'react'
import TeamMembers from './TeamMembers'

const Team = () => {
  return (
    <section className="py-0 bg-dark" id="team">
      <div className="container !pt-0 bg-primary">

        <div className="max-w-3xl">
          <h2 className="mb-6 border-b-2 border-red-400">Team</h2>

          <p id="inquiries">If you wish to contact us about this project or corpus, please address your email to <a href="mailto:amathur@princeton.edu,angelina.wang@princeton.edu,c.schwem2er@gmail.com,maiahamin@gmail.com,bms4@princeton.edu,arvindn@cs.princeton.edu">all of us.</a></p>
          <TeamMembers />
          <p className="mb-4">We are researchers affiliated with Princeton University’s Center for Information Technology Policy (CITP).</p>
          <p className="text-sm text-gray-500">We are grateful to Mihir Kshirsagar, Matthew Salganik, Andy Guess and Orestis Papakyriakopoulos for feedback on our paper. We also thank Justin Grimmer and Jonathan Mayer for advice at earlier stages of the project. We are also grateful to Ballotpedia and OpenSecrets for providing and making their data available to us. This project was funded in part by generous support from the Data Driven Social Science Initiative at Princeton University.</p>
        </div>

      </div>
    </section>
  )
}

export default Team
