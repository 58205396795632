import React from 'react'
import Layout from '../../containers/Layout'
import Press from './components/Press'
import Inquiry from './components/Inquiry'
import Team from './components/Team'
import Seo from '../../containers/SEO'
import Faq from '../about/components/FAQ'

function About() {
  return (
    <Layout>
      <Seo title='About Us' />
      <div className='relative'>
        <section className='pt-10 pb-0 -mb-4'>
          <div className='container bg-primary'></div>
        </section>
        <Team />
        <Faq />
        <Press />
      </div>
    </Layout>
  )
}

export default About
